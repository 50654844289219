export const name = `蘇葵 | Su Kui`;

export const paragraph1 = `Su Kui is an artist who uses photographs as materials to create images between reality and illusion. Based on the interest in objects and her childhood experience of talking to, disassembling, and reassembling them, her work focuses on objects, exploring her internal experiences and investigating in topics such as the social meaning of objects and how they interact with each other, spaces, and people. `;

export const paragraph2 = `Selected Awards:
Liquida Photofestival, Special Mention,2022.
Passepartout Photography Prize, Honorable Mention,2022.
TOP20·2021 Chinese contemporary photography exhibition, Selected Artist,2021.
The 2020-2021 Signature Art Prize (China), Annual Photography Art Prize,2021.
New Talents 2021, Photography Exploration Project, Selected Artist,2021.
The Natural Art Post-85s Chinese Photographer Project (2020), Grand Prize,2020.
Artand 2nd Chinese Young Artists Selection, Jury's Choice Award,2019.
Sony World Photography Awards, Shortlist,2019.
International Photography Grant, Nominee,2019.`

export const paragraph3 = `Exhibitions include Chengdu Contemporary Image Museum, Shanghai Lafayette Art &Design Center, Beijing C5art ,403 International Art Center, Zhejiang Art Museum, Somerset House, Kommunale Gallerie, the 28th Photo L.A., Lishui Photography Festival, Pingyao International Photography Festival.`

export const paragraph4 = `Publications include Musée Magazine, Yogurt: MOJO flavour, Chopsuey Magazine, See-Zeen Magazine, Zonemag,Broad Magazine, Fraction Magazine, Grandmama’s Print, Chip Foto-Video Digital, Chinese Photography, People’s Photography, Popular Photography, HELLO Chengdu.`
