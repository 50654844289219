import AnonymityImg from '../../assets/img/objects/Anonmity.jpg';
import BlackHoleImg from '../../assets/img/objects/Black-hole.png';
import CombinationImg from '../../assets/img/objects/Combination.jpg';
import GradeImg from '../../assets/img/objects/Grade.jpg';
import GrandHotelImg from '../../assets/img/objects/Grand-hotel.jpg';
import IsolationImg from '../../assets/img/objects/Isolation.jpg';
import LargeCapacityMahjongRoomImg from '../../assets/img/objects/Large-capacity-mahjong-room.jpg';
import MonitorImg from '../../assets/img/objects/Monitor.jpg';
import NeatFreakImg from '../../assets/img/objects/Neat-freak.jpg';
import ObservationDeckImg from '../../assets/img/objects/Observation-deck.jpg';
import OccasionalityImg from '../../assets/img/objects/Occasionality.jpg';
import OptionsImg from '../../assets/img/objects/Options.jpg';
import OrderImg from '../../assets/img/objects/Order.jpg';
import PleaseDrawTheCurtainImg from '../../assets/img/objects/Please-draw-the-curtain.jpg';
import PrintImg from '../../assets/img/objects/Print.jpg';
import SlideImg from '../../assets/img/objects/Slide.jpg';
import TheSecondFloorImg from '../../assets/img/objects/The-second-floor.jpg';
import VIPTreatmentImg from '../../assets/img/objects/VIP-treatment.jpg';
import WeChatRoundTheAbyssImg from '../../assets/img/objects/We-chat-round-the-abyss.jpg';
import WhenLightsTurnOnImg from '../../assets/img/objects/When-lights-turn-on.jpg';
import DissipateHeatImg from '../../assets/img/objects/Dissipate-heat.jpg';

export const TITLE = 'The System of Objects';
export const TITLE_Description = '(Ongoing)';

export const DESCRIPTIONS = [
  `Space is always full of objects. The tangible space is also a kind of object. 
  The two are closely related. Objects can affect and shape our definition, understanding and impression of spaces.
  The System of Objects focuses on interior spaces especially modern residential spaces with the structure of spaces, 
  the shape of objects in them, and the way they are displayed. 
  Through transforming objects in spaces and sometimes even putting objects belonging to the public space in the private ones, 
  I suspend the function of objects and spaces and replace them with ones that cannot be easily classified. 
  It presents some conflicting, interesting, uncoordinated and confused scenes.
  `,
  `In daily life, we will divide furniture into chairs, beds, lamps, sofas and so forth. 
  The residential space is usually composed of bedroom, study, dining room, living room, kitchen, toilet and so forth. 
  Each category of objects and rooms has a process of "evolution". 
  For example, I am curious about why the objects and rooms are the way they are now, what kind of evolutionary history it has, 
  what kind of psychological projection it reflects in addition to carrying practical needs. Each space is not a natural existence, 
  even private space also carries social functions.`,
  `The transformation of them shows my exploration of self-consciousness. 
  The objective meaning is covered by my subjective intention. Here, I try to create my own "system of objects". 
  More importantly, I hope to discuss how we definite the objects and spaces and explore the complex correlation among objects, spaces, society. 
  In this context, the manufacture of objects is not only a technical issue. 
  Its appearance and function are not only based on aesthetic and practical needs, but also show profound sociological principles. 
  People project various desires on them and satisfy themselves by giving them some concepts. 
  The defamiliarization of objects and spaces provides a new way to perceive, understand, and reflect on these matters.`,
];

export const images = [
  {
    src: OrderImg,
    text: 'Order',
  },
  {
    src: OccasionalityImg,
    text: 'Occasionality',
  },
  {
    src: TheSecondFloorImg,
    text: 'The Second Floor',
  },
  {
    src: GradeImg,
    text: 'Grade',
  },
  {
    src: WhenLightsTurnOnImg,
    text: 'When Lights Turn On',
  },
  {
    src: PleaseDrawTheCurtainImg,
    text: 'Please Draw the Curtain',
  },
  {
    src: BlackHoleImg,
    text: 'Black Hole',
  },
  {
    src: SlideImg,
    text: 'Slide',
  },
  {
    src: IsolationImg,
    text: 'Isolation',
  },
  {
    src: WeChatRoundTheAbyssImg,
    text: 'Sit around the abyss',
  },
  {
    src: DissipateHeatImg,
    text: 'Dissipate Heat',
  },
  {
    src: CombinationImg,
    text: 'Combination',
  },
  {
    src: ObservationDeckImg,
    text: 'Observation Deck',
  },
  {
    src: AnonymityImg,
    text: 'Anonymity',
  },
  {
    src: MonitorImg,
    text: 'Monitor',
  },
  {
    src: PrintImg,
    text: 'Print',
  },
  {
    src: NeatFreakImg,
    text: 'Neat Freak',
  },
  {
    src: VIPTreatmentImg,
    text: 'VIP Treatment',
  },
  {
    src: LargeCapacityMahjongRoomImg,
    text: 'Large Capacity Mahjong Room',
  },
  {
    src: GrandHotelImg,
    text: 'Grand Hotel',
  },
  {
    src: OptionsImg,
    text: 'Options',
  },
];
