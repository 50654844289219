import aPoemWithoutWordsJpg from '../../assets/img/daydream/A-poem-without-words.jpg';
import balanceJpg from '../../assets/img/daydream/Balance.jpg';
import birthdayCakeJpg from '../../assets/img/daydream/Birthday-cake.jpg';
import butterflyEffectJpg from '../../assets/img/daydream/Butterfly-effect.jpg';
import cardGameJpg from '../../assets/img/daydream/Card-game.jpg';
import conundrumJpg from '../../assets/img/daydream/Conundrum.jpg';
import dividedJpg from '../../assets/img/daydream/Divided.jpg';
import duetJpg from '../../assets/img/daydream/Duet.jpg';
import fervidityJpg from '../../assets/img/daydream/Fervidity.jpg';
import gregariousJpg from '../../assets/img/daydream/Gregarious.jpg';
import heartJpg from '../../assets/img/daydream/Heart.jpg';
import insoluableJpg from '../../assets/img/daydream/Insoluable.jpg';
import interruptJpg from '../../assets/img/daydream/Interrupt.jpg';
import landingJpg from '../../assets/img/daydream/Landing.jpg';
import linkJpg from '../../assets/img/daydream/Link.jpg';
import lipJpg from '../../assets/img/daydream/Lip.jpg';
import loveGameJpg from '../../assets/img/daydream/Love-game.jpg';
import matrixJpg from '../../assets/img/daydream/Matrix.jpg';
import pearlJpg from '../../assets/img/daydream/Pearl.jpg';
import playBilliardsJpg from '../../assets/img/daydream/Play-billiards.jpg';
import prickingJpg from '../../assets/img/daydream/Pricking.jpg';
import printABalloonJpg from '../../assets/img/daydream/Print-a-balloon.jpg';
import squeezeJpg from '../../assets/img/daydream/Squeeze.jpg';
import tennisCourtJpg from '../../assets/img/daydream/Tennis-court.jpg';
import tenseJpg from '../../assets/img/daydream/Tense.jpg';
import theRelativePositionJpg from '../../assets/img/daydream/The-relative-position.jpg';
import touchJpg from '../../assets/img/daydream/Touch.jpg';
import traumaJpg from '../../assets/img/daydream/Trauma.jpg';
import triangleJpg from '../../assets/img/daydream/Triangle.jpg';
import twoGamesJpg from '../../assets/img/daydream/Two-games.jpg';

export const TITLE = 'Daydream';

export const DESCRIPTIONS = [
  `Objects could be considered as the principle character in our life instead of something unnoticeable, which is the original intention of Daydream-In the Name of Object. It mainly focuses on the neglected small objects in life and tries to liberate commonplace things from the specific context and inertia of daily life as well as explore their interesting characteristics. The novelty and tension of objects can be discovered through the expressivity of objects. 
 `,
  `Through the reconstructing and abstracting process of images, or even creating a“mistake”, I hope to stimulate a sense of ambiguity and confusion over photography and to explore the boundaries of how photography can be presented. Meanwhile, an intriguing question is raised:“In which context could an image be considered as photography?” However，the context to answer this question remains undefined, only time can tell.`,
];

export const images = [
  {
    src: aPoemWithoutWordsJpg,
    text: 'A Poem Without Words',
  },
  {
    src: balanceJpg,
    text: 'Balance',
  },
  {
    src: birthdayCakeJpg,
    text: 'Birthday Cake',
  },
  {
    src: butterflyEffectJpg,
    text: 'Butterfly Effect',
  },
  {
    src: cardGameJpg,
    text: 'Card Game',
  },
  {
    src: conundrumJpg,
    text: 'Conundrum',
  },
  {
    src: dividedJpg,
    text: 'Divided',
  },
  {
    src: duetJpg,
    text: 'Duet',
  },
  {
    src: fervidityJpg,
    text: 'Fervidity',
  },
  {
    src: gregariousJpg,
    text: 'Gregarious',
  },
  {
    src: heartJpg,
    text: 'Heart',
  },
  {
    src: insoluableJpg,
    text: 'Insoluable',
  },
  {
    src: interruptJpg,
    text: 'Interrupt',
  },
  {
    src: landingJpg,
    text: 'Landing',
  },
  {
    src: linkJpg,
    text: 'Link',
  },
  {
    src: lipJpg,
    text: 'Lip',
  },
  {
    src: loveGameJpg,
    text: 'Love Game',
  },
  {
    src: matrixJpg,
    text: 'Matrix',
  },
  {
    src: pearlJpg,
    text: 'Pearl',
  },
  {
    src: playBilliardsJpg,
    text: 'Play Billiards',
  },
  {
    src: prickingJpg,
    text: 'Pricking',
  },
  {
    src: printABalloonJpg,
    text: 'Print A Balloon',
  },
  {
    src: squeezeJpg,
    text: 'Squeeze',
  },
  {
    src: tennisCourtJpg,
    text: 'Tennis Court',
  },
  {
    src: tenseJpg,
    text: 'Tense',
  },
  {
    src: theRelativePositionJpg,
    text: 'The Relative Position',
  },
  {
    src: touchJpg,
    text: 'Touch',
  },
  {
    src: traumaJpg,
    text: 'Trauma',
  },
  {
    src: triangleJpg,
    text: 'Triangle',
  },
  {
    src: twoGamesJpg,
    text: 'Two Games',
  },
];
