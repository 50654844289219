import styles from './style.less';

const Contact = () => {
  return (
    <div className={styles.contact}>
      <p>Email: Iriskui@outlook.com</p>
      <p>Weibo: 苏葵</p>
    </div>
  );
};

export default Contact;
